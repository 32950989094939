import Cookies from "js-cookie";
import { Suspense, lazy, useContext, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "./Hooks/Context";
import "./Pages/HomeAdmin/Family/AllCss.css";
import axios from "axios";
import { API } from "./Config/Data";
import Toast from "./Components/sweetalert";

const Login = lazy(() => import("./Pages/Login/Login"));
const Admin = lazy(() => import("./Pages/HomeAdmin/Admin"));
const FamTy = lazy(() => import("./Pages/HomeAdmin/Family/FamilyType/FamTy"));
const Home = lazy(() => import("./Pages/HomeAdmin/Home/Home"));
const Users = lazy(() => import("./Pages/HomeAdmin/Users/Users"));
const FamClas = lazy(() =>
  import("./Pages/HomeAdmin/Family/FamilyClassification/FamClas")
);
const Fund = lazy(() => import("./Pages/HomeAdmin/Fund/Fund"));
const Orphan = lazy(() => import("./Pages/HomeAdmin/Fund/orphan/orphan"));
const OrphanAdd = lazy(() => import("./Pages/HomeAdmin/Fund/orphan/Add"));
const OrphanEdit = lazy(() => import("./Pages/HomeAdmin/Fund/orphan/Edit"));
const FundPerson = lazy(() => import("./Pages/HomeAdmin/Fund/FundPerson"));
const KindSub = lazy(() => import("./Pages/HomeAdmin/Subventions/KindSub"));
const Family = lazy(() =>
  import("./Pages/HomeAdmin/Family/GroupFamily/Family")
);
const Persons = lazy(() => import("./Pages/HomeAdmin/Persons/Persons"));
const AddFamily = lazy(() =>
  import("./Pages/HomeAdmin/Family/GroupFamily/Add")
);
const EditFamily = lazy(() =>
  import("./Pages/HomeAdmin/Family/GroupFamily/Edit")
);
const AddPerson = lazy(() => import("./Pages/HomeAdmin/Persons/Add"));
const EditPerson = lazy(() => import("./Pages/HomeAdmin/Persons/Edit"));
const Sub = lazy(() => import("./Pages/HomeAdmin/Subventions/Sub"));
const AddSub = lazy(() => import("./Pages/HomeAdmin/Subventions/Add"));
const EditSub = lazy(() => import("./Pages/HomeAdmin/Subventions/Edit"));
const FamilyPerson = lazy(() => import("./Pages/HomeAdmin/FamilyPerson/view"));
const FamilyPersonDet = lazy(() =>
  import("./Pages/HomeAdmin/FamilyPerson/Details")
);
const AddFamPer = lazy(() => import("./Pages/HomeAdmin/FamilyPerson/Add"));
function App() {
  const location = useLocation();
  let p_token = Cookies.get("p_token");
  let username = Cookies.get("username");
  const nav = useNavigate();
  const [newData, setNewData] = useState({
    password: "",
    password_confirmation: "",
  });
  const [inputFeild, setInputFeild] = useState({
    password: false,
    password_confirmation: false,
  });
  const { addPage, setAddPage, addTrue, setAddTrue } = useContext(AuthContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .put(
        `${API.admin.auth_manager_change_profile_pas2w0rd}`,
        {
          username: username,
          password: newData.password,
          password_confirmation: newData.password_confirmation,
        },
        {
          headers: {
            Authorization: `Bearer ${p_token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            "admin-cha-auth-ing-key": "a1985849-bbb3-416a-acce-dabc5c9cf10d",
            "admin-cha-auth-ing-value": "55fa5b3c-c55e-4b1c-a71e-5ca9ecb2de4e",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        Toast.fire({
          icon: "success",
          text: "تمت عملية التعديل بنجاح",
        });
        Cookies.remove("p_token");
        Cookies.remove("username");
        Cookies.remove("roleKey");
        Cookies.remove("roleValue");
        nav("/Login");
        setNewData((prev) => {
          return {
            ...prev,
            username: "",
            password: "",
            password_confirmation: "",
            role: "",
          };
        });
        setAddTrue(false);
        setAddPage(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err?.response?.data?.errSt?.includes("ERR404")) {
          Toast.fire({
            icon: "warning",
            text: `${err.response.data.msg}`,
          });
        }
        if (
          err?.response?.data?.msg?.includes(
            "الحساب ملغى، لا يمكن إكمال العملية"
          )
        ) {
          Toast.fire({
            icon: "warning",
            text: `${err.response.data.msg}`,
          });
          setAddTrue(false);
          setAddPage(false);
          setNewData((prev) => {
            return {
              ...prev,
              password: "",
              password_confirmation: "",
            };
          });
          setInputFeild((prev) => {
            return {
              ...prev,
              password: false,
              password_confirmation: false,
            };
          });
        }
        if (
          err?.response?.data?.message?.includes(
            "The password field confirmation does not match."
          )
        ) {
          Toast.fire({
            icon: "warning",
            text: `تأكيد كلمة السر غير متطابقة`,
          });
          setNewData((prev) => {
            return {
              ...prev,
              password_confirmation: "",
            };
          });
        }
        if (err?.response?.data?.message?.includes("Unauthenticated.")) {
          Toast.fire({
            icon: "question",
            text: `انتهت صالحية الجلسة`,
          });
          nav("/Login");
        }
      });
  };
  return (
    <div className="App">
      {addPage && <div className="bg_color"></div>}
      {addTrue && (
        <div className="front_group" data-aos="fade-right">
          <form onSubmit={submitHandler}>
            <div className="from_group">
              <div className="from_child">
                <div
                  className="label_place"
                  label-place={username ? "اسم المستخدم" : ""}
                >
                  <input
                    type="text"
                    value={username}
                    disabled
                    placeholder="اسم المستخدم"
                    // onChange={(e) => handleChange(e)}
                  />
                </div>
                <div
                  className="label_place"
                  label-place={newData.password ? "كلمة السر" : ""}
                >
                  <input
                    type="text"
                    name="password"
                    value={newData.password}
                    placeholder="كلمة السر"
                    onChange={(e) => handleChange(e)}
                    style={{
                      border: inputFeild.password ? "2px solid brown" : "",
                    }}
                    minLength={2}
                    required
                    onInvalid={(e) => {
                      e.target.setCustomValidity(
                        "كلمة المرور مطلوبة , وعلى الأقل محرفان"
                      );
                      setInputFeild((prev) => {
                        return {
                          ...prev,
                          password: true,
                        };
                      });
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity("");
                      setInputFeild((prev) => {
                        return {
                          ...prev,
                          password: false,
                        };
                      });
                    }}
                  />
                </div>
              </div>
              <div className="from_child">
                <div
                  className="label_place"
                  label-place={
                    newData.password_confirmation ? "تأكيد كلمة المرور" : ""
                  }
                >
                  <input
                    type="text"
                    name="password_confirmation"
                    value={newData.password_confirmation}
                    placeholder="تأكيد كلمة المرور"
                    onChange={(e) => handleChange(e)}
                    style={{
                      border: inputFeild.password_confirmation
                        ? "2px solid brown"
                        : "",
                    }}
                    minLength={2}
                    required
                    onInvalid={(e) => {
                      e.target.setCustomValidity(
                        "تأكيد كلمة المرور مطلوبة , وعلى الأقل محرفان"
                      );
                      setInputFeild((prev) => {
                        return {
                          ...prev,
                          password_confirmation: true,
                        };
                      });
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity("");
                      setInputFeild((prev) => {
                        return {
                          ...prev,
                          password_confirmation: false,
                        };
                      });
                    }}
                  />
                </div>
              </div>
              <button className="button">تغيير</button>
            </div>
          </form>
          <div
            className="close"
            onClick={() => {
              setAddTrue(false);
              setAddPage(false);
              setNewData((prev) => {
                return {
                  ...prev,
                  password: "",
                  password_confirmation: "",
                };
              });
              setInputFeild((prev) => {
                return {
                  ...prev,
                  password: false,
                  password_confirmation: false,
                };
              });
            }}
          >
            <i className="bx bx-x"></i>
          </div>
        </div>
      )}
      <Suspense fallback={<></>}>
        <Routes location={location} key={location.pathname}>
          <Route index path="/Login" element={<Login />}></Route>
          <Route path="*" element={<Navigate to={"/Login"} />}></Route>
          <Route path="/*" element={<Navigate to={"/Login"} />}></Route>
          {p_token && (
            <Route path="/Admin" element={<Admin />}>
              <Route path="Home" element={<Home />} />
              <Route path="Users" element={<Users />} />
              <Route path="Family" element={<Family />}></Route>
              <Route path="AddFamily" element={<AddFamily />}></Route>
              <Route path="EditFamily/:id" element={<EditFamily />}></Route>
              <Route path="FamilyType" element={<FamTy />}></Route>
              <Route path="FamilyClassification" element={<FamClas />}></Route>
              <Route path="Fund" element={<Fund />}></Route>
              <Route path="Orphan" element={<Orphan />}></Route>
              <Route path="OrphanAdd" element={<OrphanAdd />}></Route>
              <Route path="OrphanEdit/:id" element={<OrphanEdit />}></Route>
              <Route path="FundPerson" element={<FundPerson />}></Route>
              <Route path="kindSubventions" element={<KindSub />}></Route>
              <Route path="Subventions" element={<Sub />}></Route>
              <Route path="AddSubventions" element={<AddSub />}></Route>
              <Route path="EditSubventions/:id" element={<EditSub />}></Route>
              <Route path="Persons" element={<Persons />}></Route>
              <Route path="AddPerson" element={<AddPerson />}></Route>
              <Route path="EditPerson/:id" element={<EditPerson />}></Route>
              <Route path="FamilyPerson" element={<FamilyPerson />}></Route>
              <Route
                path="FamilyPersonDet/:id"
                element={<FamilyPersonDet />}
              ></Route>
              <Route path="AddFamPer" element={<AddFamPer />}></Route>
            </Route>
          )}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
