// const BASIC_URL = "http://192.168.100.111:8000/api";
// const URL_Image = "http://192.168.100.111:8000";
// const BASIC_URL = "http://recruitment-api.alyousser.com/api";
// const URL_Image = "http://recruitment-api.alyousser.com";
// const BASIC_URL = "http://localhost:8000/api";
// const URL_Image = "http://localhost:8000";

const BASIC_URL = "https://dashboard.ertiqaa-sy.com/api";
const URL_Image = "https://dashboard.ertiqaa-sy.com";

const config = {
  api: {
    admin: {
      login: `${BASIC_URL}/admin/login-to-account`,
      logout: `${BASIC_URL}/logout-admin/logout-admin-from-account`,
      URL_Image: `${URL_Image}/storage`,
      auth_manager_get_all_admin: `${BASIC_URL}/auth-manager/get-all-admin`,
      auth_manager_change_admin_profile_status: `${BASIC_URL}/auth-manager/change-admin-profile-status`,
      create_new_admin_from_manager: `${BASIC_URL}/auth-manager/create-new-admin-from-manager`,
      auth_manager_change_profile_pas2w0rd: `${BASIC_URL}/auth-manager/change-admin-profile-pas2w0rd`,
      operation_get_all_person_data_as_excel: `${BASIC_URL}/operation/get-all-person-data-as-excel`,
    },
    orphan: {
      get_all_orphan_fund_details_data: `${BASIC_URL}/fund-details/get-all-fund-details-data`,
      fund_details_create_new_fund_detail_data: `${BASIC_URL}/fund-details/create-new-fund-detail-data`,
      fund_details_change_account_status_by_id: `${BASIC_URL}/fund-details/change-account-status-by-id`,
      fund_details_update_fund_detail_data_by_id: `${BASIC_URL}/fund-details/update-fund-detail-data-by-id`,
      fund_details_get_fund_details_data_by_id: `${BASIC_URL}/fund-details/get-fund-details-data-by-id`,
    },
    family: {
      families_create_new_family: `${BASIC_URL}/families/create-new-family`,
      families_get_all_family_data_by_id: `${BASIC_URL}/families/get-all-family-data-by-id`,
      families_update_family_data_by_id: `${BASIC_URL}/families/update-family-data-by-id`,
      get_all_family_types: `${BASIC_URL}/family-types/get-all-family-types`,
      delete_family_type_by_id: `${BASIC_URL}/family-types/delete-family-type-by-id`,
      families_get_all_families_data: `${BASIC_URL}/families/get-all-families-data`,
      families_delete_family_data_by_id: `${BASIC_URL}/families/delete-family-data-by-id`,
      family_types_create_new_family_type: `${BASIC_URL}/family-types/create-new-family-type`,
      family_types_update_family_type_by_id: `${BASIC_URL}/family-types/update-family-type-by-id`,
      family_types_get_family_type_by_id: `${BASIC_URL}/family-types/get-family-type-by-id`,
      family_classifications_get_family_classification_by_id: `${BASIC_URL}/family-classifications/get-family-classification-by-id`,
      family_classifications_create_new_family_classification: `${BASIC_URL}/family-classifications/create-new-family-classification`,
      family_classifications_update_family_classification_by_id: `${BASIC_URL}/family-classifications/update-family-classification-by-id`,
    },
    people: {
      create_new_passer_by_data: `${BASIC_URL}/people/create-new-passer-by-data`,
      get_all_person_data: `${BASIC_URL}/people/get-all-person-data`,
      delete_person_data_by_id: `${BASIC_URL}/people/delete-person-data-by-id`,
      create_normal_person_data: `${BASIC_URL}/people/create-normal-person-data`,
      get_person_info_by_id: `${BASIC_URL}/people/get-person-info-by-id`,
      update_normal_person_data: `${BASIC_URL}/people/update-normal-person-data`,
    },
    classifications: {
      get_all_family_classification: `${BASIC_URL}/family-classifications/get-all-family-classification`,
      delete_family_classification_by_id: `${BASIC_URL}/family-classifications/delete-family-classification-by-id`,
    },
    funds: {
      get_all_funds_data: `${BASIC_URL}/funds/get-all-funds-data`,
      delete_fund_by_id: `${BASIC_URL}/funds/delete-fund-by-id`,
      funds_get_fund_by_id: `${BASIC_URL}/funds/get-fund-by-id`,
      funds_create_new_fund: `${BASIC_URL}/funds/create-new-fund`,
      funds_update_fund_by_id: `${BASIC_URL}/funds/update-fund-by-id`,
    },
    FundPerson: {
      get_all_fund_person_data: `${BASIC_URL}/fund-person/get-all-fund-person-data`,
      create_new_fund_person_data: `${BASIC_URL}/fund-person/create-new-fund-person-data`,
      get_fund_person_data_by_fund_id: `${BASIC_URL}/fund-person/get-fund-person-data-by-fund-id`,
      delete_fund_person_data_by_id: `${BASIC_URL}/fund-person/delete-fund-person-data-by-id`,
      create_new_fund_person_data_by_force: `${BASIC_URL}/fund-person/create-new-fund-person-data-by-force`,
    },
    operation: {
      transfer_data_to_edu_fund: `${BASIC_URL}/operation/transfer-data-to-edu-fund`,
      get_all_statistics: `${BASIC_URL}/operation/get-all-statistics`,
    },
    testing: {
      get_all_test_data: `${BASIC_URL}/get-all-test-data`,
    },
    FamilyPerson: {
      get_all_family_person_data: `${BASIC_URL}/family-person/get-all-family-person-data`,
      get_family_person_data_by_id: `${BASIC_URL}/family-person/get-family-person-data-by-id`,
      update_family_person_data_by_id: `${BASIC_URL}/family-person/update-family-person-data-by-id`,
      create_family_person_data: `${BASIC_URL}/family-person/create-family-person-data`,
      delete_family_person_data_by_id: `${BASIC_URL}/family-person/delete-family-person-data-by-id`,
    },
    Sub: {
      get_all_subvention_data: `${BASIC_URL}/subventions/get-all-subvention-data`,
      delete_subvention_data_by_id: `${BASIC_URL}/subventions/delete-subvention-data-by-id`,
      create_new_subvention_data: `${BASIC_URL}/subventions/create-new-subvention-data`,
      get_subvention_by_id: `${BASIC_URL}/subventions/get-subvention-by-id`,
      update_subvention_data_by_id: `${BASIC_URL}/subventions/update-subvention-data-by-id`,
    },
    kind_subventions: {
      create_new_subvention: `${BASIC_URL}/in-kind-subventions/create-new-in-kind-subvention`,
      get_subvention_by_id: `${BASIC_URL}/in-kind-subventions/get-in-kind-subvention-data-by-id`,
      update_subvention_by_id: `${BASIC_URL}/in-kind-subventions/update-in-kind-subvention-data-by-id`,
      get_all_in_kind_subvention_data: `${BASIC_URL}/in-kind-subventions/get-all-in-kind-subvention-data`,
      delete_in_kind_subvention_data_by_id: `${BASIC_URL}/in-kind-subventions/delete-in-kind-subvention-data-by-id`,
    },
  },
};
export const API = config.api;
