import Swal from "sweetalert2";

//import SwalWrapper from './Swal';
// SwalWrapper.showSuccess('Success!', 'Your changes have been saved.');
// SwalWrapper.showError('Error!', 'Something went wrong.');
// SwalWrapper.showWarning('Warning!', 'You are about to delete a record.');
// SwalWrapper.showInfo('Info!', 'This is just an informational alert.');

// const showAlert = (title, text, type) => {
//   Swal.fire({
//     title: title,
//     text: text,
//     icon: type,
//     confirmButtonText: "Cool",
//     timer: 3000,
//   });
// };
// const SwalWrapper = {
//   showSuccess: (title, text) => showAlert(title, text, "success"),
//   showError: (title, text) => showAlert(title, text, "error"),
//   showWarning: (title, text) => showAlert(title, text, "warning"),
//   showInfo: (title, text) => showAlert(title, text, "info"),
// };
// export default SwalWrapper;
const Toast = Swal.mixin({
  toast: true,
  position: "top-start",
  showConfirmButton: false,
  timer: 2500,
  background: "var(--basic-color)",
  color: "#fff",
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
const SwalMixin = {
  methods: {
    showSuccess(title, text) {
      Swal.fire({
        title: title,
        text: text,
        icon: "success",
        confirmButtonText: "Cool",
        timer: 3000,
      });
    },
    showError(title, text) {
      Swal.fire({
        title: title,
        text: text,
        icon: "error",
        confirmButtonText: "Cool",
        timer: 3000,
      });
    },
    showWarning(title, text) {
      Swal.fire({
        title: title,
        text: text,
        icon: "warning",
        confirmButtonText: "Cool",
        timer: 3000,
      });
    },
    showInfo(title, text) {
      Swal.fire({
        title: title,
        text: text,
        icon: "info",
        confirmButtonText: "Cool",
        timer: 3000,
      });
    },
  },
};
// export default SwalMixin;
export default Toast;
