import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [addPage, setAddPage] = useState(false);
  const [addTrue, setAddTrue] = useState(false);
  return (
    <AuthContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        token,
        setToken,
        addPage,
        setAddPage,
        addTrue,
        setAddTrue,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
